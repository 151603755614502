import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CONFIG } from '../helpers/interceptor.service';
import { Credentials } from '../models/credentials.model';
import { FormattedResponse } from '../models/formatted-response';
import { HttpContextConfig } from '../models/http-context-config';
import {Role, User} from '../models/user.model';
import { environment } from '../../environments/environment';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) {
  }

  currentUser: BehaviorSubject<User | any> = new BehaviorSubject(null);

  login(credentials: Credentials) {
    return this.http.post<FormattedResponse<any>>(`${environment.api}/auth/login`, credentials);
  }

  register(credentials: Credentials) {
    return this.http.post<FormattedResponse<any>>(`${environment.api}/auth/register`, credentials);
  }

  logout() {
    return this.http.get<FormattedResponse<any>>(`${environment.api}/auth/logout`);
  }

  me() {
    return this.http.get<FormattedResponse<User>>(`${environment.api}/auth/me`);
  }

  passwordRecovery(credentials: Credentials) {
    return this.http.post<FormattedResponse<any>>(`${environment.api}/auth/password-recovery`, credentials);
  }
  passwordRecoveryBackOffice(email: Credentials) {
    return this.http.post<FormattedResponse<any>>(`${environment.api}/auth/password-recovery`, { email: email });
  }

  passwordChange(credentials: Credentials) {
    return this.http.post<FormattedResponse<any>>(`${environment.api}/auth/change-password`, credentials);
  }

  passwordReset(credentials: Credentials) {
    return this.http.post<FormattedResponse<any>>(`${environment.api}/auth/password-reset`, credentials);
  }

  getUser(isInitializer = false): Observable<FormattedResponse<User>> {
    let config = new HttpContextConfig();
    config.isInitializer = isInitializer;

    const context = new HttpContext().set(CONFIG, config);
    return this.http.get<FormattedResponse<User>>(`${environment.api}/auth/me`, { context: context });
  }

  logoutUser(user_id: string) {
    return this.http.get<FormattedResponse<any>>(`${environment.api}/auth/logout${user_id}`);
  }


  redirectUser(router: Router) {
    if (this.currentUser.value.email_verified_at == null) router.navigate([`/auth/password-change`])
    else if (this.currentUser.value.roles[0].id == Role.USER) router.navigate([`/app/dashboard`]);
    else if ([Role.BACKOFFICE, Role.ADMIN].includes(this.currentUser.value.roles[0].id)) router.navigate([`/back-office`]);
  }
}
