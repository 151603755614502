export interface User {
  id: number | string;
  email: string;
  name: string;
  surname: string;
  full_name: string;
  password?: string;
  password_confirmation?: string;
  verification_code?: string;
  role: RoleDetail;
  date_time_access: Date;
  created_at?: string;
  updated_at?: string;
}

export interface RoleDetail {
  id: number;
  name?: string;
  display_name?: string;
  description?: string;
  pivot?: any;
}

export enum Role {
  ADMIN = 1,
  BACKOFFICE = 2,
  USER = 3
}
