import {Injectable, TemplateRef} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Configuration} from '../models/configuration.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  public footer: BehaviorSubject<Configuration | null> = new BehaviorSubject<Configuration | null>(null);
  public message: BehaviorSubject<string | TemplateRef<void>> = new BehaviorSubject<string | TemplateRef<void>>('Qualcosa è andato storto');
}
