import { Component, Input } from '@angular/core';
import { BaseComponent } from "../base/base.component";
import { FormattedResponse } from "../../shared/models/formatted-response";
import { ButtonConfiguration } from "../../shared/models/configuration.model";

@Component({
  selector: 'app-jumbotron',
  templateUrl: './jumbotron.component.html',
  styleUrl: './jumbotron.component.scss'
})
export class JumbotronComponent extends BaseComponent {
  @Input() color: 'blue' | 'orange' | 'light-blue' | 'brown' | 'yellow' = 'blue';
  @Input() template: string = this.jumbotronTemplates.welcome;
  @Input() actions: ButtonConfiguration[] = [];
  @Input() turnTo: boolean = false;
  @Input() turnToCustom: boolean = false;
  @Input() turnToConfiguration = { label: '', link: '' };

  @Input() isLogout: boolean = true;


  /** Consente all'utente di effettuare la disconnessione dell'account */
  logout() {
    localStorage.clear();
    this.authService.currentUser.next(null);
    this.authService.logout().subscribe({
      next: (response: FormattedResponse<any>) => {
        this.router.navigate(['/auth/login']);
      }
    })
  }
}
