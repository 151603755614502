import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { TableColumn } from "../../shared/models/datatable.model";
import { ButtonConfiguration } from "../../shared/models/configuration.model";
import { Paginator } from '../../shared/models/page.model';
import moment from 'moment';
import { VotingEvent } from '../../shared/models/voting-event.model';

@Component({
  standalone: false,
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrl: './datatable.component.scss',
  host: { ngSkipHydration: 'true' },
})
export class DatatableComponent extends BaseComponent {
  moment = moment;
  @Output() changeElementPresence: EventEmitter<any> = new EventEmitter<any>;
  @Output() pageIndexChanged: EventEmitter<number> = new EventEmitter<number>();
  @Input() logoutMode: boolean = false

  listOfColumn: TableColumn<any>[] = [
    {
      key: 'name',
      title: 'Name',
    },
    {
      key: 'chinese',
      title: 'Chinese Score',
    },
    {
      key: 'math',
      title: 'Math Score',
    },
    {
      key: 'english',
      title: 'English Score',
    },
    {
      key: 'actions',
      title: 'Azioni'
    }
  ];

  @Input() template: TemplateRef<any> | undefined;
  @Input() listOfData: any = [
    {
      name: 'John Brown',
      chinese: 98,
      math: 60,
      english: 70
    },
    {
      name: 'Jim Green',
      chinese: 98,
      math: 66,
      english: 89
    },
    {
      name: 'Joe Black',
      chinese: 98,
      math: 90,
      english: 70
    },
    {
      name: 'Jim Red',
      chinese: 88,
      math: 99,
      english: 89
    }
  ];
  @Input() configuration: { buttons: ButtonConfiguration[], columns: TableColumn<any>[] } = { buttons: [], columns: this.listOfColumn }
  @Input() mode: 'create' | 'edit' | 'details' = 'details';

  @Input() paginator: Paginator = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }

  openModal(type: 'detail' | 'delete', element: any, template?: TemplateRef<any>) {
    switch (type) {
      case 'detail': {
        this.modalService.create({
          nzTitle: `${element.name}`,
          nzContent: template
        });
        break;
      }
      case 'delete': {
        this.modalService.confirm({
          nzTitle: 'Sei sicuro di voler eliminare questo elemento?',
          // nzContent: '<b style="color: red;">Some descriptions</b>',
          nzOkText: 'Sì',
          nzOkType: 'primary',
          nzOkDanger: true,
          nzOnOk: () => console.log('OK'),
          nzCancelText: 'Annulla',
          nzOnCancel: () => console.log('Cancel')
        });
        break;
      }
      default: { }
    }
  }

  checkStatus(data: any, i: number): boolean {
    if (!data.date_time_access) {
      return true
    } else {
      return false
    }
  }
  changePresence(element: any, index: number, event: boolean) {
    element.delegation.presence = event;
    this.changeElementPresence.emit({ element, index, event });
  }

  pageIndexChange(index: number) {
    this.pageIndexChanged.emit(index);
  }

  renderCellValue(column: TableColumn<any>, data: any): string {
    if (data[column.key] != null) {
      if (typeof data[column.key] === 'object' && column.subkey) {
        return data[column.key][column.subkey];
      }
      return data[column.key];
    } else {
      return '----';
    }
  }


}
