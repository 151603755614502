import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthService } from '../../shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { ConfigurationService } from '../../shared/helpers/configuration.service';
import {
  VotingEvent,
  VotingEventElectionType,
  VotingEventType,
  VotingEventVisibility
} from '../../shared/models/voting-event.model';
import { VotingEventsService } from "../../shared/services/voting-events.service";
import { it_IT, NzI18nService } from "ng-zorro-antd/i18n";
import { UsersService } from '../../shared/services/users.service';
import { DelegationsService } from '../../shared/services/delegations.service';
import { ElectoralRollsService } from '../../shared/services/electoral-rolls.service';
import moment from 'moment';
import { VotersService } from '../../shared/services/voters.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrl: './base.component.scss'
})
export class BaseComponent {
  /** Inject di tutti i servizi utili */
  public activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  public authService: AuthService = inject(AuthService);
  public changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  public configurationService: ConfigurationService = inject(ConfigurationService);
  public delegationsService: DelegationsService = inject(DelegationsService);
  public electoralRollsService: ElectoralRollsService = inject(ElectoralRollsService);
  public formBuilder: FormBuilder = inject(FormBuilder);
  public i18nService: NzI18nService = inject(NzI18nService);
  public modalService: NzModalService = inject(NzModalService);
  public notificationService: NzNotificationService = inject(NzNotificationService);
  public usersService: UsersService = inject(UsersService);
  public votersService: VotersService = inject(VotersService);
  public votingEventsService: VotingEventsService = inject(VotingEventsService);
  public cdref: ChangeDetectorRef = inject(ChangeDetectorRef);

  public router: Router = inject(Router);

  private readonly strongPasswordRegex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-])[A-Za-z\\d$&+,:;=?@#|'<>.^*()%!-]{8,}$";

  /** Regex di controllo per validatori */
  public regex = {
    name: "^([a-zA-Z\xE0\xE8\xE9\xF9\xF2\xEC\x27\ s]?)+$",
    email: "[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}",
    password: this.strongPasswordRegex,
    phone: "^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{3,4})(?: *x(\\d+))?\\s*$",
    mobile: "^([+]39)?\\s?((313)|(32[0123456789])|(33[013456789])|(35[0123456789])|(34[0123456789])|(36[0368])|(37[0123456789])|(38[0389])|(39[0123]))[\\s-]?([\\d]{7})$",
    vatNumber: "([0-9]{11})",
    fiscal_code: "^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$",
    url: "https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)",
    vatNumberFiscalCode: "^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$|([0-9]{11})"
  };

  public jumbotronTemplates = {
    welcome: `<article class="d-flex flex-row flex-xl-row-reverse justify-content-between gap-3">\n` +
      `      <div>\n` +
      `        <h6 class="color-base">Bentornato</h6>\n` +
      `        <h1 class=\"color-base fw-bold\">\n` +
      this.authService.currentUser.value?.name + `\n` +
      this.authService.currentUser.value?.surname + `</h1> \n` +
      `      </div>\n` +
      `      <img src="../../../assets/imgs/avatar.svg" alt="User avatar">\n` +
      `    </article>`,
    votingEvents: `<h1 class=\"color-base fw-bold\">Gestisci sessioni di votazione</h1>`,
    voters: `<h1 class=\"color-base fw-bold\">Gestisci elettori</h1>`,
    users: `<h1 class=\"color-base fw-bold\">Gestisci utenti</h1>`,
    online_users: `<h1 class=\"color-base fw-bold\">Utenti connessi</h1>`,
    delegations: `<h1 class=\"color-base fw-bold\">Gestisci deleghe</h1>`
  };

  public selectedVotingEvent = '1';
  public statics: any = {
    election_types: [
      { label: 'Lista', value: 'list' },
      { label: 'Uninominale', value: 'uninominal' }
    ],
    vote_types: [
      { label: 'Singolo', value: 'single' },
      { label: 'Distribuito', value: 'distributed' }
    ],
    voter_types: [
      { label: 'Esecutore', value: 'Executor' },
      { label: 'Finanziatore', value: 'Lender' }
    ],
    visibilities: [
      { label: 'Pubblico', value: 'public' },
      { label: 'Privato', value: 'private' }
    ]
  };

  getVotingEventStatus(event: VotingEvent) {
    if (event) {
      let status = 'closed';

      if (moment(event.opened_time).isSameOrBefore(moment(), 'day') && (moment(event.closed_time).isAfter(moment(), "day") || !event.closed_time)) status = 'open';
      else if ((moment(event.opened_time).isAfter(moment(), 'day') && (moment(event.closed_time).isAfter(moment(), "day")) || !event.closed_time)) status = 'pending';

      return status;
    }
    return null;
  }

  hasMobileDevice() {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  }
}
