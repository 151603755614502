// Angular
import { Inject, Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
  HttpContextToken, HttpContext
} from '@angular/common/http';
// RxJS
import { map, Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LoaderService } from './loader.service';
import { Router } from '@angular/router';
import { HttpContextConfig } from '../models/http-context-config';
import { NzNotificationComponent, NzNotificationService } from 'ng-zorro-antd/notification';
import { DOCUMENT } from '@angular/common';
import { ConfigurationService } from "./configuration.service";
import { AuthService } from "../services/auth.service";

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */

export const CONFIG = new HttpContextToken<HttpContextConfig>(() => new HttpContextConfig());

@Injectable({
  providedIn: 'root'
})

export class InterceptorService implements HttpInterceptor {
  constructor(
    private loaderService: LoaderService,
    private configurationService: ConfigurationService,
    private router: Router,
    private notificationService: NzNotificationService,
    private authService: AuthService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let csrf = localStorage.getItem('csrf');

    // set withCredentials to true to send cookies
    if (csrf) {
      request = request.clone({
        setHeaders: {
          'x-csrf-token': csrf,
        },
        withCredentials: true
      });
    } else {
      request = request.clone({
        withCredentials: true
      });
    }

    // check if the request have NO_SPINNER context
    if (request.context.get(CONFIG)) {
      let config = request.context.get(CONFIG);
      if (!config.noSpinner) {
        this.loaderService.setLoading(true, request.url);
      }

      if (config.isPublic) {
        // console.log("this is a public request");
        /*this.translate.get(['toast.info.100_TITLE', 'toast.info.100_TEXT']).subscribe(message =>
          toast.fire({
            title: message['toast.info.100_TITLE'],
            text: message['toast.info.100_TEXT'],
            icon: 'info'
          }
          )
        );*/
      }
    }

    return next.handle(request).pipe(
      map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
        if (evt instanceof HttpResponse) {
          this.loaderService.setLoading(false, request.url);

          if (request.url.indexOf('/protected') != -1) {
            if (request.method != 'GET')
              this.notificationService.success('Evviva!', evt.body.message);
          }
        }
        return evt;
      })
    )
      .pipe(tap((event) => {
      }),
        catchError((err) => {
          let config = request.context.get(CONFIG);
          this.loaderService.setLoading(false, request.url);

          if (config.isInitializer) {
            return of<any>(null);
          }

          if (new RegExp('[4,5]0[0-5]').test(err.status)) {
            this.notificationService.error('Errore', err.error.message);
          } else {
            this.notificationService.error('Errore', this.configurationService.message.value);
          }

          if (err.status === 401) {
            this.authService.logout();
            this.router.navigate(['/auth/login']);
          }
          if (err.status === 403) {
            history.back();
          }
          return throwError(err.error);
        })
      );
  }
}
