import { Component, Input } from '@angular/core';
import { Route } from '../../shared/models/route.model';
import { BaseComponent } from "../base/base.component";
import { Role } from "../../shared/models/user.model";

@Component({
  standalone: false,
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss',
  host: { ngSkipHydration: 'true' },
})
export class BreadcrumbComponent extends BaseComponent {
  @Input() color: "base" | "primary" | "secundary" = "base";
  @Input() routes: Route[] = [
    { path: '/components', name: 'Componenti', icon: null, disabled: true },
    { path: '/components/datatable', name: 'Datatable', icon: null, disabled: false }
  ];

  @Input() turnTo: boolean = false;
  @Input() turnToConfiguration = {
    label: 'Torna alla home',
    link: this.authService.currentUser.value.roles[0].id == Role.USER ? '/app/dashboard' : '/back-office/dashboard'
  };

  protected readonly Role = Role;
}
