<section [class]="'jumbotron-' + color">
  <section>
    @if (turnTo) {
    <app-breadcrumb [turnTo]="turnTo"></app-breadcrumb>
    }
    @if (turnToCustom) {
    <app-breadcrumb [turnTo]="turnToCustom" [turnToConfiguration]="turnToConfiguration"></app-breadcrumb>
    }
    <article [innerHTML]="template"></article>
  </section>

  @if (actions.length > 0) {
  <article class="d-flex flex-row flex-wrap mt-3 gap-3">
    @for (button of actions; track button) {
    <button nz-button [nzBlock]="button.nzBlock" [nzType]="$any(button.nzType || 'default')"
      [nzDanger]="button.nzDanger" (click)="button.action ? button.action() : null" [routerLink]="button.link"
      [class]="button.class" [disabled]="button.disabled">
      {{ button.label }}
    </button>
    }
  </article>
  } @else if(!turnToCustom) {
  <section class="pt-4 mt-2">
    <a nz-button nzType="link" (click)="logout()">
      <span nz-icon><img src="../../../../../assets/icons/logout.svg" alt="Logout"></span>
      <span class="label color-base">Logout</span>
    </a>
  </section>
  }
</section>