<nz-table #sortTable [nzData]="listOfData" nzTableLayout="fixed" (nzPageIndexChange)="pageIndexChange($event)"
    [nzPageIndex]="paginator.pageIndex" [nzPageSize]="paginator.pageSize" [nzTotal]="paginator.total"
    [nzFrontPagination]="false" [nzShowPagination]="true" [nzPaginationPosition]="'bottom'">
    <thead>
        <tr>
            @for (column of configuration.columns; track column) {
            <th>
                {{ column.title }}
            </th>
            }
        </tr>
    </thead>
    <tbody>
        @for (data of sortTable.data; track data; let i = $index) {
        <tr>
            @for (column of configuration.columns; track column) {
            @switch (column.key) {
            @case ('presence') {
            <td>
                <nz-input-group>
                    <nz-select class="w-50" [(ngModel)]="data[column.key]" [nzDisabled]="mode == 'details'"
                        (ngModelChange)="changePresence(data, i, $event)">
                        <nz-option [nzValue]="1" [nzLabel]="'Presente'"></nz-option>
                        <nz-option [nzValue]="0" [nzLabel]="'Assente'"></nz-option>
                    </nz-select>
                </nz-input-group>
            </td>
            }
            @case ('actions') {
            <td class="actions">
                @for (button of configuration.buttons; track button) {
                <button nz-button nzBlock [nzType]="$any(button.nzType || 'default')"
                    (click)="button.action ? button.action(data, mode, i) : null" [routerLink]="button.link"
                    [class]="button.class + ' ' + (button.class?.includes('details-hide') && mode == 'details' ? 'd-none' : 'd-block')"
                    [disabled]=" logoutMode && logoutMode == true ? checkStatus(data,i) : button.disabled"
                    [nzBlock]="button.nzBlock" [nzDanger]="button.nzDanger">
                    @if (button.icon) {
                    <span nz-icon [nzType]="button.icon" nzTheme="outline"></span>
                    }
                    @if (button.label != '') {
                    {{ button.label }}
                    }
                </button>
                }
            </td>
            }
            @default {
            <td>{{ renderCellValue(column, data) }}</td>
            }
            }
            }
        </tr>
        }
    </tbody>
</nz-table>