<!-- <nav class="navbar navbar-expand-lg">
  <div class="container-fluid">
    <div class="d-flex flex-row justify-content-between">
      <a class=" navbar-brand" href="app/dashboard"><img class="logo"
          src="https://www.darwintechnologies.it/wp-content/uploads/2019/08/darwin-logo-1.png"></a>

      <ul nz-menu nzMode="horizontal">
        <li nz-menu-item *ngFor="let page of pages" [routerLink]="page.path" nzSelected>
          <span nz-icon [nzType]="page.icon!"></span>
          {{ page.name }}
        </li>
      </ul>
    </div>

    <div class="btn-group me-5">
      <button type="button" class="btn dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown"
        aria-expanded="false">
        <button type="button" class="btn">
          <nz-avatar class="text-uppercase mx-2" [ngStyle]="{ 'background-color': '#FBEAF0', 'color': '#801B3D' }"
            [nzText]="currentUser?.email?.charAt(0) || 'U'"></nz-avatar>
          <span>{{ currentUser?.email || 'Utente' }}</span>
        </button>
      </button>

      <ul class="custom-dropdown dropdown-menu">
        <li>
          <a routerLink="/app/profile"><i class="btn fa-solid fa-gear text-muted"></i> Impostazioni</a>
        </li>
        <li>
          <hr class="dropdown-divider">
        </li>
        <li class="d-flex flex-row justify-content-end mx-3">
          <span class="cursor-pointer" (click)="logout()">Esci</span>
        </li>
      </ul>
    </div>
  </div>
</nav> -->

<nz-header>
  <div class="logo"></div>
  <ul nz-menu nzTheme="dark" nzMode="horizontal" class="header-menu">
    <li nz-menu-item nzSelected>nav 1</li>
    <li nz-menu-item>nav 2</li>
    <li nz-menu-item>nav 3</li>
  </ul>
</nz-header>