<section class="mb-3">
  <nz-breadcrumb>
    @if (!turnTo) {
    @for (route of routes; track route) {
    <nz-breadcrumb-item [ngClass]="{'disabled': route.disabled}">
      <a [routerLink]="route.path" [class]="'color-' + color">
        @if (route.icon) {
        <span [class]="'color-' + color" nz-icon [nzType]="route.icon"></span>
        }
        <span>{{ route.name }}</span>
      </a>
    </nz-breadcrumb-item>
    }
    } @else {
    <nz-breadcrumb-item>
      <a [routerLink]="turnToConfiguration.link">
        <span [class]="'color-' + color" nz-icon [nzType]="'arrow-left'"></span>
        <span [class]="'color-' + color">{{ turnToConfiguration.label }}</span>
      </a>
    </nz-breadcrumb-item>
    }
  </nz-breadcrumb>
</section>