import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Item } from '../../shared/models/item.model';
import { BaseComponent } from '../base/base.component';
import { Paginator } from '../../shared/models/page.model';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  host: { ngSkipHydration: 'true' },
})
export class ListComponent extends BaseComponent {
  @Output() pageIndexChanged: EventEmitter<number> = new EventEmitter<number>();

  @Input() items: Item[] = [];
  @Input() type: 'inline' | 'block' | 'delegation' = 'block';
  @Input() noData: { title: string, description?: string, image?: boolean } = { title: 'Nessuna sessione attiva' };
  @Input() size: string = '';
  @Input() paginator: Paginator = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }

  pageIndexChange(index: number) {
    this.pageIndexChanged.emit(index);
  }
}
