import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormattedResponse } from '../models/formatted-response';
import { environment } from '../../environments/environment';
import { VotingEvent } from "../models/voting-event.model";
import { Observable } from "rxjs";
import { Delegation } from '../models/delegation.model';

@Injectable({
  providedIn: 'root'
})
export class VotingEventsService {
  constructor(private http: HttpClient) {
  }

  getVotingEvents(parameters?: any): Observable<FormattedResponse<VotingEvent[]>> {
    let params = new HttpParams();
    if (parameters?.pageIndex) params = params.append('skip', ((parameters?.pageIndex - 1) * parameters?.pageSize));
    if (parameters?.pageSize) params = params.append('take', parameters?.pageSize);

    if (parameters?.search) {
      params = params.append('search', JSON.stringify(parameters?.search));
    }

    params = params.append('order', JSON.stringify({ column: 'opened_date', dir: 'DESC' }));

    return this.http.get<FormattedResponse<VotingEvent[]>>(`${environment.api}/voting-events`, { params });
  }

  getVotersByVotingEvent(id: string): Observable<FormattedResponse<VotingEvent[]>> {
    return this.http.get<FormattedResponse<VotingEvent[]>>(`${environment.api}/voting-events/${id}/voters`);
  }

  getDelegationsByVotingEvent(id: string): Observable<FormattedResponse<Delegation[]>> {
    return this.http.get<FormattedResponse<Delegation[]>>(`${environment.api}/voting-events/${id}/delegations`);
  }

  getVotingEvent(id: string): Observable<FormattedResponse<VotingEvent>> {
    return this.http.get<FormattedResponse<VotingEvent>>(`${environment.api}/voting-events/${id}`);
  }

  storeVotingEvent(data: any) {
    return this.http.post<FormattedResponse<VotingEvent>>(`${environment.api}/voting-events`, data);
  }

  storeVoterForVotingEvent(id: string, data: any) {
    return this.http.post<FormattedResponse<VotingEvent>>(`${environment.api}/voting-events/${id}/voters`, data);
  }

  putVotingEvent(id: string, data: any) {
    return this.http.put<FormattedResponse<VotingEvent>>(`${environment.api}/voting-events/${id}`, data);
  }

  deleteVotingEvent(id: string): Observable<FormattedResponse<VotingEvent>> {
    return this.http.delete<FormattedResponse<VotingEvent>>(`${environment.api}/voting-events/${id}`);
  }

  deleteVoterForVotingEvent(voting_event: string, voter: string): Observable<FormattedResponse<VotingEvent>> {
    return this.http.delete<FormattedResponse<VotingEvent>>(`${environment.api}/voting-events/${voting_event}/voters/${voter}`);
  }

  vote(data: any) {
    return this.http.post<FormattedResponse<VotingEvent>>(`${environment.api}/vote`, data);
  }

  getReport(id: string) {
    return this.http.get<FormattedResponse<any>>(`${environment.api}/voting-events/${id}/report`);
  }

  getReportPDF(id: string) {
    return this.http.get<FormattedResponse<any>>(`${environment.api}/voting-events/${id}/report-pdf`, {
      'headers': {
        'toast': 'hidden',
        'Content-Type': 'application/json', 'customHeader': 'blob'
      }, 'responseType': "blob" as "json"
    });
  }
}
