<section class="list">
  @if (items.length > 0) {
  @for (item of items; track item) {
  <section [class]="item.class ?? ''">
    @switch (type) {
    @case ('inline') {
    <article class="list-element d-flex flex-row justify-content-between">
      <div style="width: 70%;">
        <h5 [class]="{'logo-header': item.icon}"
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ item.label }}</h5>
      </div>

      <article class="d-flex flex-row gap-3">
        @for (button of item.actions; track button) {
        <button nz-button [nzBlock]="button.nzBlock" [nzType]="$any(button.nzType || 'default')"
          [nzDanger]="button.nzDanger" (click)="button.action ? button.action() : null" [routerLink]="button.link"
          [class]="button.class" [disabled]="button.disabled">
          @if (button.icon) {
          <span nz-icon [nzType]="button.icon" nzTheme="outline"></span>
          }
          @if (button.label != '') {
          {{ button.label }}
          }
        </button>
        }
      </article>
    </article>
    }
    @case ('delegation') {
    <article class="list-element">
      <article class="d-flex flex-row"
        [ngClass]="item.params?.status ? 'justify-content-between w-100' : 'justify-content-start'">
        <h5 [class]="{'logo-header': item.icon}">{{ item.label }}</h5>
      </article>

      <article class="d-flex flex-row justify-content-start mt-3 mb-1">
        <img [src]="'../../../assets/icons/voter.svg'" alt="Voter">
        <span class="ms-2">Delega per conto di: <strong>{{ item.params.voter.full_name }}</strong></span>
      </article>

      <article class="d-flex flex-row justify-content-start mb-3">
        <img [src]="'../../../assets/icons/delegation.svg'" alt="Delegation">
        <span class="ms-2">Delegato: <strong>{{ item.params.user.name }} {{ item.params.user.surname }}</strong></span>
      </article>

      <article class="d-flex flex-row mt-3 w-100 gap-3">
        @for (button of item.actions; track button) {
        <button nz-button [nzBlock]="button.nzBlock" [nzType]="$any(button.nzType || 'default')"
          [nzDanger]="button.nzDanger" (click)="button.action ? button.action() : null" [routerLink]="button.link"
          [class]="button.class" [disabled]="button.disabled">
          {{ button.label }}
        </button>
        }
      </article>
    </article>
    }
    @case ('block') {
    <article [ngClass]="{'list-element': !item.background, 'list-element-with-image': item.background}">
      @if (item.params?.closed == true && item.background) {
      <nz-tag class="d-inline-block" nzColor="processing">
        <span nz-icon>
          <ng-container *ngTemplateOutlet="infoIconTemplate"></ng-container>
        </span>
        <span>Votazione conclusa</span>
      </nz-tag>
      }@else if (item.params?.voted == true && item.background) {
      <nz-tag class="d-inline-block" nzColor="success">
        <span nz-icon>
          <ng-container *ngTemplateOutlet="successIconTemplate"></ng-container>
        </span>
        <span>Votazione effettuata!</span>
      </nz-tag>
      }
      @else if (item.params?.inactive == true && item.background) {
      <nz-tag class="d-inline-block" nzColor="warning">
        <span nz-icon>
          <ng-container *ngTemplateOutlet="alertIconTemplate"></ng-container>
        </span>
        <span>Votazione inattiva</span>
      </nz-tag>
      }
      @else {
      <span class="d-block">&nbsp;</span>
      }

      <article class="d-flex flex-row"
        [ngClass]="item.params?.status ? 'justify-content-between w-100' : 'justify-content-start'">
        @if (item.icon) {
        <img class="logo me-3" [src]="item.icon">
        }
        <h4 [class]="{'logo-header': item.icon}">{{ item.label }}</h4>
        @if (item.params?.status && item.params?.status != 'closed') {
        <nz-tag class="status d-none d-xl-inline-block ms-3"
          [nzColor]="item.params?.status == 'open' ? 'success' : 'warning'">
          <span nz-icon>
            <ng-container [ngTemplateOutlet]="statusIconTemplate"
              [ngTemplateOutletContext]="{ status: item.params?.status }"></ng-container>
          </span>
          <span>{{ item.params?.status == 'open' ? 'Attiva' : item.params?.status == 'pending' ? 'In arrivo' :
            ''}}</span>
        </nz-tag>
        }
      </article>
      @if (item.params?.date) {
      <h6 class="color-grey-2">{{ item.params?.date | date:'dd/MM/YYYY' }}</h6>
      }
      <article>
        @if (item.params?.visibility) {
        <img [src]="'../../../assets/icons/' + item.params?.visibility + '-vote.svg'">
        <span class="ms-1">Votazione {{ item.params?.visibility == 'public' ? 'palese' : 'segreta' }}</span>
        }
      </article>

      @if (item.params?.delegate) {
      <nz-alert nzType="info" nzMessage="" nzDescription="Sei stato delegato per questa votazione" nzShowIcon
        [nzIcon]="infoIconTemplate"></nz-alert>
      }
      @if (item.params?.voted && !item.background) {
      <nz-alert nzType="success" nzMessage="" nzDescription="Votazione effettuata!" nzShowIcon
        [nzIcon]="successIconTemplate"></nz-alert>
      }

      <article class="d-flex flex-row mt-3 w-100 gap-3">
        @for (button of item.actions; track button) {
        <button nz-button [nzBlock]="button.nzBlock" [nzType]="$any(button.nzType || 'default')"
          [nzDanger]="button.nzDanger" (click)="button.action ? button.action() : null" [routerLink]="button.link"
          [class]="button.class" [disabled]="button.disabled">
          {{ button.label }}
        </button>
        }
      </article>
    </article>
    }
    }
  </section>

  }
  <article class="w-100 d-flex flex-row justify-content-end">
    <nz-pagination [nzPageIndex]="paginator.pageIndex" [nzPageSize]="paginator.pageSize" [nzTotal]="paginator.total"
      (nzPageIndexChange)="pageIndexChange($event)"></nz-pagination>
  </article>
  } @else {
  <nz-empty class="flex-xl-grow-1" nzNotFoundImage="simple" [nzNotFoundContent]="noDataTemplate"
    nzNotFoundImage="../../../assets/imgs/no-data.svg"></nz-empty>
  }

  <ng-template #noDataTemplate>
    <article class="mt-3 gap-4">
      <h5 class="fw-bold">{{ noData.title }}</h5>
      <p>{{ noData.description }}</p>
    </article>
  </ng-template>
</section>

<ng-template #infoIconTemplate>
  <img nz-icon [src]="'../../../assets/icons/info-filled.svg'">
</ng-template>

<ng-template #alertIconTemplate>
  <img nz-icon [src]="'../../../assets/icons/alert.svg'">
</ng-template>

<ng-template #successIconTemplate>
  <img nz-icon [src]="'../../../assets/icons/success.svg'">
</ng-template>

<ng-template #statusIconTemplate let-status="status">
  <img nz-icon [src]="'../../../assets/icons/' + status + '-status.svg'">
</ng-template>