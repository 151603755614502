import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormattedResponse } from '../models/formatted-response';
import { environment } from '../../environments/environment';
import { Observable } from "rxjs";
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private http: HttpClient) {
  }

  getUsers(parameters?: any): Observable<FormattedResponse<User[]>> {
    let params = new HttpParams();
    if (parameters?.pageIndex) params = params.append('skip', ((parameters?.pageIndex - 1) * parameters?.pageSize));
    if (parameters?.pageSize) params = params.append('take', parameters?.pageSize);

    if (parameters?.search) {
      params = params.append('search', JSON.stringify(parameters?.search));
    }

    params = params.append('order', JSON.stringify({ column: 'name', dir: 'ASC' }));

    return this.http.get<FormattedResponse<User[]>>(`${environment.api}/users`, { params });
  }

  storeUser(data: any) {
    return this.http.post<FormattedResponse<User>>(`${environment.api}/users`, data);
  }

  deleteUser(user: string) {
    return this.http.delete<FormattedResponse<User>>(`${environment.api}/users/${user}`);
  }

  getLoggedUsers(parameters: any) {
    let params = new HttpParams();
    if (parameters?.pageIndex) params = params.append('skip', ((parameters?.pageIndex - 1) * parameters?.pageSize));
    if (parameters?.pageSize) params = params.append('take', parameters?.pageSize);

    if (parameters?.user_status_filter || parameters?.session_filter) {
      params = params.append('user_status_filter', (parameters?.user_status_filter));
      params = params.append('session_filter', JSON.stringify(parameters?.session_filter));
    }
    if (parameters?.name) {
      params = params.append('name', parameters?.name);
    }

    params = params.append('order', JSON.stringify({ column: 'name', dir: 'ASC' }));

    return this.http.get<FormattedResponse<User[]>>(`${environment.api}/logged_in_users`, { params });
  }

  updateUser(user_id: string, user: User) {
    return this.http.put<FormattedResponse<User>>(`${environment.api}/users/${user_id}`, user);
  }

  importUsers(file: File): Observable<HttpResponse<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.post(`${environment.api}/users/import`, formData, {
      responseType: 'blob',
      observe: 'response'
    });
  }
}
