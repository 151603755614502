import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormattedResponse } from '../models/formatted-response';
import { environment } from '../../environments/environment';
import { Observable } from "rxjs";
import { Delegation } from '../models/delegation.model';

@Injectable({
  providedIn: 'root'
})
export class DelegationsService {
  constructor(private http: HttpClient) {
  }

  getDelegations(parameters?: any): Observable<FormattedResponse<Delegation[]>> {
    let params = new HttpParams();
    if (parameters?.pageIndex) params = params.append('skip', ((parameters?.pageIndex - 1) * parameters?.pageSize));
    if (parameters?.pageSize) params = params.append('take', parameters?.pageSize);

    if (parameters?.search_field) {
      params = params.append('search_field', JSON.stringify(parameters?.search_field));
    }

    // params = params.append('order', JSON.stringify({ column: 'name', dir: 'ASC' }));

    return this.http.get<FormattedResponse<Delegation[]>>(`${environment.api}/delegations`, { params });
  }

  getDelegation(id: string): Observable<FormattedResponse<Delegation>> {
    return this.http.get<FormattedResponse<Delegation>>(`${environment.api}/delegations/${id}`);
  }

  storeDelegation(data: any) {
    return this.http.post<FormattedResponse<Delegation>>(`${environment.api}/delegations`, data);
  }

  putDelegation(id: string, data: any) {
    return this.http.put<FormattedResponse<Delegation>>(`${environment.api}/delegations/${id}`, data);
  }

  deleteDelegation(id: string) {
    return this.http.delete<FormattedResponse<Delegation>>(`${environment.api}/delegations/${id}`);
  }

  getUsersByVoter(voting_event: string, voter: string, parameters?: any) {
    let params = new HttpParams();
    if (parameters?.pageIndex) params = params.append('skip', ((parameters?.pageIndex - 1) * parameters?.pageSize));
    if (parameters?.pageSize) params = params.append('take', parameters?.pageSize);

    if (parameters?.search) {
      params = params.append('search', JSON.stringify(parameters?.search));
    }

    params = params.append('order', JSON.stringify({ column: 'name', dir: 'ASC' }));

    return this.http.get<FormattedResponse<Delegation>>(`${environment.api}/voting-events/${voting_event}/delegations/voters/${voter}/users`, { params });
  }

  getVotersByDelegation(voting_event: string, parameters?: any) {
    let params = new HttpParams();
    if (parameters?.pageIndex) params = params.append('skip', ((parameters?.pageIndex - 1) * parameters?.pageSize));
    if (parameters?.pageSize) params = params.append('take', parameters?.pageSize);

    if (parameters?.search) {
      params = params.append('search', JSON.stringify(parameters?.search)); 4
    }

    params = params.append('order', JSON.stringify({ column: 'name', dir: 'ASC' }));

    return this.http.get<FormattedResponse<Delegation>>(`${environment.api}/voting-events/${voting_event}/delegations/voters`, { params });
  }
}
