import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormattedResponse } from '../models/formatted-response';
import { environment } from '../../environments/environment';
import { Observable } from "rxjs";
import { List } from '../models/voting-event.model';

@Injectable({
  providedIn: 'root'
})
export class ElectoralRollsService {
  constructor(private http: HttpClient) {
  }

  getElectoralRolls(): Observable<FormattedResponse<List[]>> {
    return this.http.get<FormattedResponse<List[]>>(`${environment.api}/electoral_rolls`);
  }

  getElectoralRoll(id: string): Observable<FormattedResponse<List>> {
    return this.http.get<FormattedResponse<List>>(`${environment.api}/electoral_rolls/${id}`);
  }

  storeElectoralRoll(data: any) {
    return this.http.post<FormattedResponse<List>>(`${environment.api}/electoral_rolls`, data);
  }

  putElectoralRoll(id: string, data: any): Observable<FormattedResponse<List>> {
    return this.http.put<FormattedResponse<List>>(`${environment.api}/electoral_rolls/${id}`, { data });
  }

  deleteElectoralRoll(id: string): Observable<FormattedResponse<List>> {
    return this.http.delete<FormattedResponse<List>>(`${environment.api}/electoral_rolls/${id}`);
  }
}
