import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormattedResponse } from '../models/formatted-response';
import { environment } from '../../environments/environment';
import { Observable } from "rxjs";
import { Voter } from '../models/voting-event.model';

@Injectable({
  providedIn: 'root'
})
export class VotersService {
  constructor(private http: HttpClient) {
  }

  getVoters(parameters?: any): Observable<FormattedResponse<Voter[]>> {
    let params = new HttpParams();
    if (parameters?.pageIndex) params = params.append('skip', ((parameters?.pageIndex - 1) * parameters?.pageSize));
    if (parameters?.pageSize) params = params.append('take', parameters?.pageSize);

    if (parameters?.search) {
      params = params.append('search', JSON.stringify(parameters?.search)); 4
    }

    params = params.append('order', JSON.stringify({ column: 'full_name', dir: 'ASC' }));

    return this.http.get<FormattedResponse<Voter[]>>(`${environment.api}/voters`, { params });
  }

  getVoter(id: string): Observable<FormattedResponse<Voter>> {
    return this.http.get<FormattedResponse<Voter>>(`${environment.api}/voters/${id}`);
  }

  storeVoter(data: any) {
    return this.http.post<FormattedResponse<Voter>>(`${environment.api}/voters`, data);
  }

  putVoter(id: string, data: any): Observable<FormattedResponse<Voter>> {
    return this.http.put<FormattedResponse<Voter>>(`${environment.api}/voters/${id}`, data);
  }

  deleteVoter(id: string): Observable<FormattedResponse<Voter>> {
    return this.http.delete<FormattedResponse<Voter>>(`${environment.api}/voters/${id}`);
  }

  importVoters(file: File): Observable<HttpResponse<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.post(`${environment.api}/voters/import`, formData, {
      responseType: 'blob',
      observe: 'response'
    });
  }
}
