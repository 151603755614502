import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NgZorroAntdModule } from './ng-zorro-antd/ng-zorro-antd.module';
import { DatatableComponent } from './datatable/datatable.component';
import { ComponentsRoutingModule } from './components-routing.module';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { BaseComponent } from './base/base.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListComponent } from './list/list.component';
import { JumbotronComponent } from './jumbotron/jumbotron.component';
import { BaseChartDirective } from 'ng2-charts';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables)
Chart.register(ChartDataLabels);

@NgModule({
  imports: [
    CommonModule,
    ComponentsRoutingModule,
    FormsModule,
    BaseChartDirective,
    NgZorroAntdModule,
    ReactiveFormsModule
  ],
  declarations: [
    BaseComponent,
    BreadcrumbComponent,
    DatatableComponent,
    FooterComponent,
    HeaderComponent,
    ListComponent,
    SidebarComponent,
    JumbotronComponent,
  ],
  exports: [
    NgZorroAntdModule,
    BaseComponent,
    BreadcrumbComponent,
    DatatableComponent,
    FooterComponent,
    FormsModule,
    HeaderComponent,
    JumbotronComponent,
    ListComponent,
    ReactiveFormsModule,
    SidebarComponent,
    BaseChartDirective,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule {
}
