@if ((configurationService.footer | async)?.active) {
  <article class="actions">
    @for (button of (configurationService.footer | async)?.buttons; track button) {
      <button nz-button nzBlock [nzType]="$any(button.nzType || 'default')"
              (click)="button.action ? button.action() : null" [routerLink]="button.link" [class]="button.class"
              [disabled]="button.disabled">
        {{ button.label }}
      </button>
    }
  </article>
}
