import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() isCollapsed: boolean = true;

  public routes = [
    { label: 'Home', icon: 'home', link: '/home' },
    { label: 'About', icon: 'info', link: '/about' }
  ];

  constructor(
  ) { }

  ngOnInit(): void {
  }
}
